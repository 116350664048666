/**
 * auth
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 2.18
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */



export interface AuthEditUserRequest {
    /**
     * User ID
     */
    user?: string;
    /**
     * The new user\'s role within the company
     */
    role?: AuthEditUserRequest.RoleEnum;
}
export namespace AuthEditUserRequest {
    export type RoleEnum = 'admin' | 'superuser' | 'user' | 'annotator';
    export const RoleEnum = {
        Admin: 'admin' as RoleEnum,
        Superuser: 'superuser' as RoleEnum,
        User: 'user' as RoleEnum,
        Annotator: 'annotator' as RoleEnum
    }
}
